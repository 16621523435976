
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { mapGetters } from 'vuex';
import SVGBGMountain from '../../assets/svg/mountainbg.svg';
import SVGBGMountainBgGreen from '../../assets/svg/mountainbgGreen.svg';
import SVGBGArrowSwiper from '../../assets/svg/arrowSwiper.svg';

export default {
  components: {
    SVGBGMountain,
    SVGBGMountainBgGreen,
    SVGBGArrowSwiper
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      currentSlideIndex: 0,
      isSafari: false,
      readyToDisplay: false
    };
  },
  computed: {
    ...mapGetters({
      isProductDetailsSticky: 'ui/isProductDetailsSticky'
    })
  },
  beforeMount() {
    document.addEventListener('keydown', this.onKeyupEscape);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyupEscape);
  },
  mounted() {
    // eslint-disable-next-line
    this.slideShow = new Swiper('.swiper', {
      pagination: {
        el: '.swiper-pagination'
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev'
      }
    });

    // We need to do that because:
    // 1. overflow:clip is not supported on wanted browser
    // 2. Using overflow:hidden is not possible because of sticky
    // 3. Applying contain paint to chrome cause animation flickering
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    this.slideShow.on('slideChange', () => {
      this.currentSlideIndex = this.slideShow.activeIndex;
    });

    // Workaround to hide fixed div at loading time in background
    setTimeout((a) => {
      this.readyToDisplay = true;
    }, 1000);
  },
  methods: {
    onKeyupEscape(e) {
      console.log(e.keyCode);
      if (e.keyCode === 37) {
        this.slidePrev();
      } else if (e.keyCode === 39) {
        this.slideNext();
      }
    },
    getDynamicDelay(index, element, currentSlideIndex) {
      let delay = (element.ingredients.length - index) * 150 + 'ms';
      if (currentSlideIndex === 1) {
        delay = index * 150 + 'ms';
      }
      return delay;
    },
    slidePrev() {
      this.slideShow.slidePrev();
    },
    slideNext() {
      this.slideShow.slideNext();
    },
    getCurrentTitle(index) {
      return [...this.data.list[index].name];
    }
  }
};
